import { defineComponent } from 'vue';
export default defineComponent({
    name: 'OptionalAgree',
    setup() {
        const qcellsPrivacyPolicy_AU = 'https://qcells.com/au/footer/privacy-policy';
        return {
            qcellsPrivacyPolicy_AU,
        };
    },
});
